@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* La font-size de html sert de base pour le calcul des rem */
/* -------------------------------------------------------- */

html {
  /* Cette valeur correspond à la division de 16px (taille de la font-size de base) par la width de l'écran utilisé
       pour les maquettes (1920px) multiplié par 100 pour obtenir une valeur en % => 16/1920 = 0.00833 * 100 = 0.833 */
  font-size: 0.8333333333333vw;
  scroll-behavior: smooth;
}
/* ------------------------------------------------------- */

body {
  font-family: 'Jost', 'sans-serif';
  color: white;
  background-color: black;
  scrollbar-color: rgba(255, 0, 0, 0.2) rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;
}

/* Pour Webkit browsers comme Chrome/Safari */

::-webkit-scrollbar {
  width: 12px; /* Largeur de la barre de défilement */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(
    20,
    20,
    20,
    0.2
  ); /* Couleur de la barre de défilement */
}

::-webkit-scrollbar-track {
  background-color: rgba(
    0,
    0,
    0,
    0.1
  ); /* Couleur de la piste de la barre de défilement */
}

.neon-container {
  position: absolute;
  width: 100%;
  height: 83.5rem;
  overflow: hidden;
  display: flex;
}

.neon-rectangle-initial {
  width: 100%;
  height: calc(50vw * 2.27);
  background: rgba(0, 0, 0, 1); /* Couleur initiale (noir complet) */
  z-index: 12;
}

.neon-rectangle-final {
  width: 100%; /* Hauteur basée sur la largeur */
  height: calc(50vw * 2.27);
  background: conic-gradient(
    from 90deg at 50% 50%,
    #e88214 0deg,
    rgba(232, 130, 20, 0.01) 159.3750035762787deg,
    rgba(0, 0, 0, 0) 240.00000715255737deg,
    rgba(0, 0, 0, 0) 360deg
  );
  z-index: 11;
}

.neon-rectangle-left {
  left: 0;
  z-index: 2;
}

.neon-rectangle-right {
  right: 0;
  transform: scaleX(-1);
  z-index: 1;
}

/* Nouveau calque pour assombrir la lumière */
.neon-mobile-static-layer {
  position: absolute;
  left: 0;
  width: 100%;
  height: 24.5rem;
  top: 10.95rem;
  background-image: url('../assets/images/bg/fond_neon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* Calque neon effect mobile sans animation */
.darkening-layer {
  position: absolute;
  left: 0;
  width: 100%;
  height: 69.75rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.27) 0%, #000 112.74%);
  z-index: 4; /* Plus élevé que les autres éléments pour être au premier plan */
}

.bg-cta-home {
  background: linear-gradient(
    270deg,
    rgba(232, 130, 20, 0.43) -8.27%,
    rgba(232, 130, 20, 0.15) 124.63%
  );
}

.vision-section {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 152.5rem;
  height: 50.875rem;
  border-radius: 100%;
  overflow: hidden;
  border-top: 1px solid #e88214;
  z-index: 20;
}

.vision-section-layer {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 152.5rem;
  height: 50.875rem;
  border-radius: 100%;
  overflow: hidden;
  z-index: 21;
}

.vision-section-layer-gradient {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 1)
  );
}

.flou {
  position: absolute;
  top: 0;
  left: 50%; /* Centrage horizontal */
  transform: translate(-50%, 0); /* Déplacement du point d'ancrage au centre */
  bottom: 0;
  color: rgba(232, 130, 20, 0.7);
  text-align: center;
  font-size: 21.875rem;
  font-weight: 500;
  will-change: filter;
  -webkit-filter: blur(10rem);
  filter: blur(10rem);
}

.vision-description {
  position: absolute;
  /*top: 24.25rem;*/
  top: 15rem;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5rem 7rem;
  width: 76.6875rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.03) 0%,
    rgba(217, 217, 217, 0) 100%
  );
  will-change: filter;
  -webkit-backdrop-filter: blur(1.5rem);
  backdrop-filter: blur(1.5rem);
  transition: top 2s ease-in-out;
}

.animate-vision-description {
  top: 24.5rem;
}

.vision-description-top-line {
  background: linear-gradient(
    90deg,
    rgba(232, 130, 20, 0.15) 2.08%,
    #e88214 50.12%,
    rgba(232, 130, 20, 0.15) 100%
  );
}

.blob {
  will-change: filter;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  position: absolute;
  z-index: -1;
  top: 0;
  opacity: 0;
  left: 0;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background: #e88214;
  transition: all 150ms ease;
}

.fakeblob {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.member-card {
  overflow: hidden;
  padding: 1.1px;
  background: transparent;
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  transition: all 100ms ease;
}

.member-card-inner {
  border-radius: 0.5rem;
  background: rgba(26, 26, 26, 0.5);
  will-change: filter;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  transition: all 100ms ease;
}

.member-card:hover .member-card-innerinner {
  background: rgba(26, 26, 26, 0.6);
  backdrop-filter: blur(100px);
  will-change: filter;
  -webkit-filter: blur(100px);
}

.social-members {
  display: inline-block;
  margin-left: 0.5rem;
}

.ellipse-background {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50rem;
  height: 10.875rem;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: 100%;
  background-color: #e88214;
  will-change: filter;
  -webkit-filter: blur(16.75rem);
  filter: blur(16.75rem);
}
.offer-card {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, #000 100%);
  backdrop-filter: blur(5.125rem);
  will-change: filter;
  -webkit-backdrop-filter: blur(5.125rem);
}

.background-cta {
  background: linear-gradient(
    277deg,
    rgba(232, 130, 20, 0.8) -24.35%,
    rgba(232, 130, 20, 0.15) 119.94%
  );
}

.shine-effect {
  width: 100%;
  opacity: 0;
  pointer-events: none; /* pour que la div ne capte pas les clics */
  transition: opacity 0.3s ease-in-out;
}

.shine {
  animation: shine 0.5s linear;
}

@keyframes shine {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.layer-bottom-darkness {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  background: black;
  z-index: 30;
}

.layer-bottom-ellipse-1 {
  position: absolute;
  left: 50%;
  top: 47rem;
  width: 25rem;
  height: 25rem;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.3);
  will-change: filter;
  -webkit-filter: blur(2.875rem);
  filter: blur(2.875rem);
  z-index: 4;
  transform: translate(-50%);
}

.layer-bottom-ellipse-2 {
  position: absolute;
  left: 50%;
  top: 53.875rem;
  width: 41.375rem;
  height: 41.375rem;
  border-radius: 100%;
  background: rgba(232, 130, 20, 0.9);
  will-change: filter;
  -webkit-filter: blur(5.125rem);
  filter: blur(5.125rem);
  z-index: 3;
  transform: translate(-50%);
}

.layer-bottom-ellipse-3 {
  position: absolute;
  left: 50%;
  top: 40.75rem;
  width: 67.5rem;
  height: 67.5rem;
  border-radius: 100%;
  background: #e88214;
  will-change: filter;
  -webkit-filter: blur(15rem);
  filter: blur(15rem);
  transform: translate(-50%);
}

.blur-container {
  position: absolute;
  left: 50%;
  top: 62.375rem;
  width: 235.375rem;
  height: 24.125rem;
  will-change: filter;
  -webkit-filter: blur(15rem);
  filter: blur(15rem);
  transform: translate(-50%);
}

.layer-bottom-ellipse-losange {
  width: 100%;
  height: 100%;
  background: #e8821480;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

/* logos section contact */

.contact-logos {
  display: flex;
  width: 15rem;
  justify-content: space-around;
}

.footer-top-line {
  width: 100%;
  height: 0.0625rem;
  background: linear-gradient(
    270deg,
    rgba(232, 130, 20, 0.15) 0%,
    #e88214 49.79%,
    rgba(232, 130, 20, 0.15) 100%
  );
}

.footer-middle-line {
  width: 100%;
  height: 0.0625rem;
  background: rgba(232, 130, 20, 0.25);
}

.star {
  position: absolute;
  width: 0.2rem;
  height: 0.2rem;
  background-color: white;
  border-radius: 50%;
  opacity: 1;
}

.star--big {
  width: 0.4rem;
  height: 0.4rem;
}

.star--twinkle {
  animation: twinkle 3s infinite alternate;
  animation-delay: 0s; /* valeur initiale, sera écrasée par JavaScript */
}

@keyframes twinkle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  /* Cette valeur correspond à la division de 16px (taille de la font-size de base) par la width de l'écran utilisé
       pour les maquettes (430px) multiplié par 100 pour obtenir une valeur en % => 16/430 = 0,03720930233 * 100 = 3.721 */
  html {
    font-size: 3.721vw;
  }
  .neon-container {
    top: 10.95rem;
    height: 24.5rem;
  }

  .neon-rectangle-initial {
    height: calc(50vw * 3.66);
  }

  .neon-rectangle-final {
    height: calc(50vw * 3.66);
    background: conic-gradient(
      from 90deg at 21.4% 50%,
      #e88214 0deg,
      rgba(232, 130, 20, 0.01) 156.50787234306335deg,
      rgba(0, 0, 0, 0) 239.87505912780762deg,
      rgba(0, 0, 0, 0) 360deg
    );
  }

  /* Nouveau calque pour assombrir la lumière */
  .darkening-layer {
    height: 24.5rem;
    top: 10.95rem;
  }

  .star {
    width: 0.1rem;
    height: 0.1rem;
  }

  .star--big {
    width: 0.2rem;
    height: 0.2rem;
  }

  .vision-section {
    width: 125.75rem;
    height: 42rem;
  }

  .vision-section-layer {
    width: 125.75rem;
    height: 42rem;
  }

  .vision-description {
    top: 11.5rem;
    padding: 2rem 2.625rem;
    width: 100%;
  }

  .animate-vision-description {
    top: 13.5rem;
  }
  .layer-bottom-ellipse-1 {
    top: 31.375rem;
    width: 12.5rem;
    height: 12.5rem;
  }

  .layer-bottom-ellipse-2 {
    top: 35.875rem;
    width: 20.6875rem;
    height: 20.6875rem;
    will-change: filter;
    -webkit-filter: blur(5.125rem);
    filter: blur(5.125rem);
  }

  .layer-bottom-ellipse-3 {
    top: 27.125rem;
    width: 33.75rem;
    height: 33.75rem;
  }

  .blur-container {
    top: 41.625rem;
    width: 117.6875rem;
    height: 12.0625rem;
  }

  .member-card {
    border: 1px solid #e88214;
  }
}
